@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Shadows+Into+Light:wght@300;400;500;700&display=swap");

html {
  font-family: "Poppins" !important;
}

main {
  margin-top: 16px;
}

body {
  font-size: calc(0.75em + 1vmin);
}
